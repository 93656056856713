.memberText {
    width: 25vw;
    padding: 0 3% 3% 3%;
}

.modalHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 5%;
    width: 80%;
}

@media screen and (max-width: 769px) {
    .memberText {
        width: 70vw;
    }
}

@media screen and (max-width: 415px) {
    .memberText {
        width: 100vw;
    }
}