.wrapperContainer {
    position: relative;
}

.wrapper {
    position: relative;
    width: 100%;
}

.wrapper::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
}

.hero-texthome {
    position: absolute;
    top: 30%;
    left: 10%;
}

.titlehome {
    color: white;
    font-size: 7vw;
    font-family: 'Rubik';
    text-transform: uppercase;
    word-wrap: break-word;
}

.boxContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 85%;
}

.boxitem {
    width: 25%;
    min-height: 27vh;
    overflow: hidden;
    border-radius: 25px;
    padding: 0.5% 1%;
    margin: 2%;
    box-shadow: 3px 3px 10px rgba(33, 38, 50, 0.10);
}

.testimonialIcon {
    width: 50px;
    height: 50px;
    top: 0;
    background: 'linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%)';
    border-radius: 9999;
    border: '4px #F8F8F8 solid'
}

.slogantext {
    text-align: center;
    padding-top: 30vh;
    padding-bottom: 10vh;
}

.whatwedo {
    background-image: url('../../images/whatwedoBg.png');
    background-repeat: no-repeat;
}

.sloganbg {
    background-image: url('../../images/sloganbg.png');
    padding: 4% 0;
}

/* for the page like design - start */
.note {
    position: relative;
    width: 45%;
    height: 80vh;
    padding: 3em 3em;
    margin: 2em auto 0 auto;
    color: #fff;
    background: #212121;
    opacity: 0.9;
    backdrop-filter: blur(40px);
    overflow: hidden;
}

.note:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 40px 40px 0;
    border-style: solid;
    border-color: #ffffff0a #ffffff0a #E2E2E2 #E2E2E2;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
    /* Firefox 3.0 damage limitation */
    display: block;
    width: 0;
}

.note.rounded {
    -moz-border-radius: 5px 0 5px 5px;
    border-radius: 5px 0 5px 5px;
}

.note.rounded:before {
    border-width: 8px;
    border-color: #fff #fff transparent transparent;
    -moz-border-radius: 0 0 0 5px;
    border-radius: 0 0 0 5px;
}

/* for the page like design - end */

.testimonialContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
}

.testimonialImg {
    width: 50%;
    height: min-content;
}


@media screen and (max-width: 767px) {
    .titlehome {
        font-size: 6vh;
    }

    .boxitem {
        min-width: 90%;
        height: fit-content;
        padding: 2%;
    }

    .slogantext {
        padding-top: 120vh;
    }

    .note {
        width: 80%;
        overflow-y: scroll;
    }

    .testimonialContainer {
        width: 100%;
    }

    .testimonialImg {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 469px) {
    .changingText {
        height: 120px;
    }
}

@media screen and (max-width: 415px) {
    .titlehome {
        font-size: 3vh;
    }

    .boxitem {
        min-width: 90%;
        padding: 3%;
    }

    .slogantext {
        padding-top: 100vh;
    }

    .note {
        width: 90%;
        padding: 1em 1.5em;
        overflow-y: scroll;

    }

    .testimonialContainer {
        width: 100%;
    }

    .testimonialImg {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 280px) {
    .slogantext {
        padding-top: 120vh;
    }
}

.headerBox {
    width: '100%';
    margin-left: 8%;
    margin-right: 8%;
    background: #FFFFFF;
}

.schoolBox {
    width: '100%';
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
    padding-bottom: 4%;
    background: #E9F5F5;
    display: flex;
    justify-content: space-between;
}

.homeImg {
    width: 100%;
    height: 520px;
    object-fit: cover;
}

.servicesImg {
    width: '100%';
    height: 280px;
    object-fit: cover;
}

.schoolImg {
    width: 100%;
    height: 518px;
    object-fit: cover;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }