.addresscolumn {
    min-width: 23%;
}

.footerlinks {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #F6F6F6;
    font-family: 'Karla';
}

.footerlinksPart1 {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #F6F6F6;
    font-family: 'Karla';
}

.footerlinksPart2 {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #F6F6F6;
    font-family: 'Karla';
}

.footerLinkItemBold {
    color: #FFFFFF;
    font-weight: 600;
    text-decoration: none;
    &:hover {
        color: #D8AB46 !important;
    }
}

.footerLinkItem {
    color: #FFFFFF;
    font-weight: 400;
    text-decoration: none;
    &:hover {
        color: #D8AB46 !important;
    }
}

.emailInput {
    background: rgba(235.88, 235.88, 235.88, 0.20);
    border: 1px #B7AFAB solid;
    font-size: 12px;
    width: fit-content;
    padding-left: 5%;
}

.joinButton {
    background: #D8AB46;
    padding: 2% 10%;
    margin-left: 2%;
}

.requestBox {
    height: 134;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 8%;
    padding-right: 8%;
    background: #F8F8F8;
}

@media screen and (max-width: 1100px) {
    .footerlinks {
        display: none;
    }
}

@media screen and (max-width: 686px) {
    .footerlinksPart1 {
        display: none;
    }

    .footerlinksPart2 {
        margin-top: 50;
    }
}

@media screen and (max-width: 662px) {
    .addresscolumn {
        width: 100%;
    }
    .requestBox {
        flex-direction: column;
    }
}

@media screen and (max-width: 415px) {
    .addresscolumn {
        width: 100%;
    }
}