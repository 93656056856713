.hero-textcontact {
    position: absolute;
    bottom: 0;
    left: 10%;
}

.titlecontact {
    color: white;
    font-size: 5vw;
    font-family: 'Rubik';
    text-transform: uppercase;
    word-wrap: break-word;
}

.formContainer {
    position: absolute;
    left: 40%;
    /* top: 60px; */
    bottom: 5vw;
    width: 50%;
    opacity: 0.90;
    background: #2D2D2D;
    box-shadow: 3px 3px 10px rgba(33, 38, 50, 0.10);
    border-radius: 20px;
    border: 0.50px #575757 solid;
    padding: 2%;
}

.formHeading {
    color: white;
    font-size: 5vh;
    font-family: 'Rubik';
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.24;
}

.formText {
    color: #DEDEDE;
    font-size: 2vh;
    font-family: 'Karla';
    font-weight: 300;
}

.contactInfoContainer {
    width: fit-content;
    padding: 5%;
}

.mapContainer {
    max-width: 720px;
    padding: 5%;
}

@media screen and (max-width: 820px) {
    .formContainer {
        position: static;
        width: 90%;
        margin: 5%
    }
}

@media screen and (max-width: 768px) {
    .titlecontact {
        font-size: 4vh;
    }

    .formContainer {
        position: static;
        width: 90%;
        margin: 5%
    }
}

@media screen and (max-width: 415px) {
    .titlecontact {
        font-size: 3vh;
    }

    .formContainer {
        position: static;
        width: 90%;
        margin: 5%
    }
}