.winnerstext {
    width: 40vw;
}

.teamContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    /* padding-bottom: 2%; */
    padding: 0 2% 2% 2%;
}

@media screen and (max-width: 769px) {
    .winnerstext {
        width: 70vw;
    }

    .teamContainer {
        padding-bottom: 20%;
    }
}

@media screen and (max-width: 415px) {
    .winnerstext {
        width: 100vw;
    }
}