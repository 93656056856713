.offerContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 10vh 0vh 10vh;
}

.offerBox {
    background: #FBFBFB;
    box-shadow: 3px 3px 10px rgba(33, 38, 50, 0.10);
    border-radius: 5px;
    border: 1px #EBEBEB solid;
    max-width: 368px;
    height: 570px;
    padding: 2%;
    margin: 2%;
}

.wwofaq {
    background-image: url('../../images/whatweoffer/wwo_bg.png');
    background-repeat: no-repeat;
    width: 80%;
}

.faqtext {
    opacity: 0.9;
    background: #212121;
    backdrop-filter: blur(40px);
    width: 35vw;
    padding: 3%;
}

@media screen and (max-width: 769px) {
    .faqtext {
        width: 80vw;
    }
}

@media screen and (max-width: 415px) {
    .faqtext {
        width: 90vw;
    }
}