.healthbannerText {
    color: white;
    font: 500 5vw 'Rubik';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: uppercase;
}

.signagetext {
    width: 40vw;
}

.procedureContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 10vh 0vh 10vh;
}

.procedureBox {
    background: #FBFBFB;
    box-shadow: 3px 3px 10px rgba(33, 38, 50, 0.10);
    border-radius: 5px;
    border: 1px #EBEBEB solid;
    max-width: 368px;
    padding: 2%;
    margin: 2%;
}

@media screen and (max-width: 769px) {
    .signagetext {
        width: 70vw;
    }
}

@media screen and (max-width: 415px) {
    .signagetext {
        width: 100vw;
    }
}
