.bookAppointmentFormContainer {
    padding: 3%;
    margin: 5%;
    background: #FBFBFB;
    border-radius: 20px;
    border: 1px #F0F0F0 solid;
    width: 50%;
}

@media screen and (max-width: 820px) {
    .bookAppointmentFormContainer {
        width: 90%;
    }
}