.bannerText {
    color: white;
    font: 500 5vw 'Rubik';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.yogatext {
    width: 40vw;
}

.boxes {
    background: #F1F6F6;
    border-radius: 10px;
    padding: 2.5%;
    width: 20%;
    margin: 2.5%;
}

.servicetext {
    width: 33vw;
}

@media screen and (max-width: 769px) {
    .yogatext {
        width: 70vw;
    }

    .boxes {
        width: 25%;
    }

    .servicetext {
        width: 80vw;
    }
}

@media screen and (max-width: 415px) {
    .yogatext {
        width: 100vw;
    }

    .boxes {
        width: 80%;
    }

    .servicetext {
        width: 100vw;
    }
}