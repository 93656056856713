.tsHead {
    color: #143142;
    font-size: 28px;
    font-family: 'Karla';
    font-weight: 700;
    text-transform: uppercase;
}

.tsSubHead {
    color: black;
    font-size: 22px;
    font-family: 'Karla';
    font-weight: 400;
}

.tsText {
    color: black;
    font-size: 16px;
    font-family: 'Karla';
    font-weight: 400;
}